import React, { useEffect } from 'react';
import { useFetchContactsQuery } from 'services/apis/documents';
import PixelDataTemplate from 'templates/documents/pixelData';

const PixelData = ({ setTitle }) => {
    const { data } = useFetchContactsQuery({});
    const list = data?.data?.contacts;
    useEffect(() => {
        setTitle('Pixel Data');
    }, []);
    return <PixelDataTemplate list={list} />;
};

export default PixelData;
