import { Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useFetchProductsQuery } from 'services/apis/productsApi';
import ProductsTemplate from 'templates/products';

const Products = ({ setTitle }) => {
    const [filterObject, setFilterObject] = useState({
        page_number: 1,
        keyword: '',
    });

    const { data, error, isLoading } = useFetchProductsQuery(filterObject);
    const list = data?.data?.products;
    const count = Math.ceil(data?.extra?.total_count / 10);

    useEffect(() => {
        setTitle('Products');
    }, []);
    return isLoading ? (
        <Spinner />
    ) : (
        <ProductsTemplate count={count} list={list || []} setFilterObject={setFilterObject} />
    );
};

export default Products;
