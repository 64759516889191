import { Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useFetchUsersQuery } from 'services/apis/users';
import AdminsTemplate from 'templates/admins';

const Admins = ({ setTitle, user }) => {
    const [filterObject, setFilterObject] = useState({
        page_number: 1,
        keyword: '',
        privilege: user?.privilege == 'admin' ? 2 : '',
    });
    const { data, error, isLoading } = useFetchUsersQuery({ user_type: 'admin_user', filterObject: filterObject });
    const list = data?.data?.users;
    const count = Math.ceil(data?.extra?.total_count / 10);

    useEffect(() => {
        setTitle('Admins');
    }, []);

    return isLoading ? (
        <Spinner />
    ) : (
        <AdminsTemplate user={user} list={list || []} count={count} setFilterObject={setFilterObject} />
    );
};

export default Admins;
