import { Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useFetchStoresQuery } from 'services/apis/storesApi';
import StoresTemplate from 'templates/stores';

const Stores = ({ setTitle }) => {
    const [filterObject, setFilterObject] = useState({
        page_number: 1,
        // page_size: filterObject?.page_number == 0 ? 200 : 10,
        keyword: '',
    });
    const { data, error, isLoading } = useFetchStoresQuery(filterObject);
    const list = data?.data?.stores;
    const count = Math.ceil(data?.extra?.total_count / 10);

    useEffect(() => {
        setTitle('Stores');
    }, []);
    return isLoading ? (
        <Spinner />
    ) : (
        <StoresTemplate count={count} list={list || []} setFilterObject={setFilterObject} />
    );
};

export default Stores;
