import { Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useFetchCouponsQuery } from 'services/apis/couponsApi';
import CouponsTemplate from 'templates/coupons';

const Coupons = ({ setTitle, user }) => {
    const [filterObject, setFilterObject] = useState({
        page_number: 1,
        keyword: '',
    });
    const { data, error, isLoading } = useFetchCouponsQuery(filterObject);
    const list = data?.data?.promocodes;
    const count = Math.ceil(data?.extra?.total_count / 10);
    useEffect(() => {
        setTitle('Coupons');
    }, []);
    return isLoading ? (
        <Spinner />
    ) : (
        <CouponsTemplate user={user} count={count} list={list || []} setFilterObject={setFilterObject} />
    );
};

export default Coupons;
