import { Checkbox, Flex, HStack } from '@chakra-ui/react';
import CEditableInput from 'common/forms/components/CEditableInput';
import CFormLabels from 'common/forms/components/CFormLabels';
import CUpload from 'common/forms/components/CUpload';
import React, { useEffect, useState } from 'react';
import { useUpdateProductMutation } from 'services/apis/productsApi';

const UpdateForm = ({ data }) => {
    const [updateProduct, { isLoading }] = useUpdateProductMutation();
    const [newLogo, setNewLogo] = useState(null);

    const handelUpdate = (val, name) => {
        const body = {
            product: { ...data, [`${name}`]: val },
        };

        updateProduct({ id: data?.id, body });
    };
    useEffect(() => {
        if (newLogo) handelUpdate(newLogo, 'image');
    }, [newLogo]);
    return (
        <Flex
            gap={6}
            width="100%"
            flexWrap="wrap"
            flexDirection="column"
            bg="white"
            p="20px"
            borderRadius="10px"
            mt={6}
        >
            <CUpload
                changeAction={(value) => setNewLogo(value)}
                label="Edit photo h=200px,w=350px"
                value={data?.image}
                type="edit"
            />
            <CEditableInput defaultValue={data?.code} name="code" handelSubmit={handelUpdate} label="Code" />
            <CEditableInput defaultValue={data?.link} name="link" handelSubmit={handelUpdate} label="Product link" />
            <CEditableInput
                defaultValue={data?.name_en}
                name="name_en"
                handelSubmit={handelUpdate}
                label="Name in English"
            />
            <CEditableInput
                defaultValue={data?.name_ar}
                name="name_ar"
                handelSubmit={handelUpdate}
                label="Name in Arabic"
            />
            <CEditableInput
                defaultValue={data?.description_en}
                name="description_en"
                handelSubmit={handelUpdate}
                label="Description in English"
                type="textarea"
            />
            <CEditableInput
                defaultValue={data?.description_ar}
                name="description_ar"
                handelSubmit={handelUpdate}
                label="Description in Arabic"
                type="textarea"
            />
            <HStack mb="5">
                <Checkbox
                    defaultChecked={data?.is_visible}
                    lineHeight="1.25"
                    alignItems="flex-start"
                    fontWeight="normal"
                    size="md"
                    onChange={(e) => handelUpdate(e.target.checked, 'is_visible')}
                />
                <CFormLabels label="In Home" />
            </HStack>
            <HStack mb="5">
                <Checkbox
                    defaultChecked={data?.is_top_deal}
                    lineHeight="1.25"
                    alignItems="flex-start"
                    fontWeight="normal"
                    size="md"
                    onChange={(e) => handelUpdate(e.target.checked, 'is_top_deal')}
                />
                <CFormLabels label="Is Top Product" />
            </HStack>
        </Flex>
    );
};

export default UpdateForm;
