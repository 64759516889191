import { Button, Text, Textarea, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useUpdateContactsMutation } from 'services/apis/documents';
const PixelDataTemplate = ({ list }) => {
    const [contacts, setContacts] = useState([]);
    const [updateContacts] = useUpdateContactsMutation();
    const includedData = [
        'tiktok-pixel',
        'snapchat-pixel',
        'twitter-pixel',
        'googletag-pixel',
        'facebook-pixel',
        'meta-data-description',
    ];
    useEffect(() => {
        setContacts(list);
    }, [list]);
    const handelUpdate = (id, key, value) => {
        updateContacts({
            id: id,
            body: {
                contact: {
                    key: key,
                    value: value,
                },
            },
        });
    };

    const handelChange = (data, index) => {
        let newData = [...contacts]; // Make a shallow copy of the contacts array
        newData[index] = { ...newData[index], value: data }; // Create a new object with updated value
        setContacts(newData);
    };

    return (
        <VStack width="100%" gap={4}>
            {contacts?.map((data, index) => {
                if (includedData?.includes(data?.key))
                    return (
                        <VStack
                            p={6}
                            alignItems="start"
                            bgColor="rgba(255, 255, 255, 1)"
                            borderRadius="8px"
                            width="100%"
                        >
                            <Text fontSize="16px" fontWeight="5oo" color="rgba(129, 129, 129, 1)">
                                {data?.key}
                            </Text>
                            <Textarea
                                onChange={(e) => handelChange(e.target.value, index)}
                                rows={8}
                                width="100%"
                                value={data?.value}
                            />
                            <Button
                                mt={2}
                                variant="solid"
                                width={{ base: '80%', md: '20%' }}
                                onClick={() => handelUpdate(data?.id, data?.key, contacts[index]?.value)}
                            >
                                Save
                            </Button>
                        </VStack>
                    );
            })}

            <Text fontSize="16px" fontWeight="5oo" color="rgba(129, 129, 129, 1)">
                example for pixel code : <br />
                {`  
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      fbq("init", "883871353535759");
      fbq("track", "PageView");`}
                {`  </script>
                <noscript id="facebook-pixel-image">`}
                {`  <img
          height="1"
          width="1"
          style="display: none"
          src="https://www.facebook.com/tr?id=883871353535759&ev=PageView&noscript=1"
        />`}
            </Text>
        </VStack>
    );
};

export default PixelDataTemplate;
