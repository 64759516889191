import { Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useFetchCtegoriesQuery } from 'services/apis/categoryApi';
import CategoriesTemplate from 'templates/categories';

const Categories = ({ setTitle }) => {
    const [filterObject, setFilterObject] = useState({
        page_number: 1,
        keyword: '',
    });
    const { data, error, isLoading } = useFetchCtegoriesQuery(filterObject);
    const list = data?.data?.categories;
    const count = Math.ceil(data?.extra?.total_count / 10);

    useEffect(() => {
        setTitle('Categories');
    }, []);

    return isLoading ? (
        <Spinner />
    ) : (
        <CategoriesTemplate list={list || []} count={count} setFilterObject={setFilterObject} />
    );
};

export default Categories;
