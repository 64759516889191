import { ChakraProvider } from '@chakra-ui/react';
import React, { useState } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFetchUserQuery } from 'services/apis/users';
import { routes } from './common/routes';
import { theme } from './common/styles/style';

function App() {
    const token = localStorage.getItem('token');
    const user_type = localStorage.getItem('privilege');
    const id = localStorage.getItem('id');
    const { data, error, isLoading } = useFetchUserQuery({ id, user_type });
    const user = data?.data?.user;
    const [title, setTitle] = useState('Dashboard');
    const router = createBrowserRouter(routes({ title, setTitle, user }));

    return (
        <>
            <ToastContainer />
            <ChakraProvider theme={theme}>
                <RouterProvider router={router} fallbackElement={<>loading</>} />
            </ChakraProvider>
        </>
    );
}

export default App;
